<template>
  <div class="container-fluid round px-0 mb-5">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 class="card-title mb-0">ตรวจสอบโพยมีปัญหา</h5>
        <button type="button" class="btn alert-warning border-warning btn-sm py-0 mr-2" @click="reload(false)">รีเฟรชในอีก {{countDown}} วินาที</button>
      </div>
      <div>
        <table class="table table-bordered table-hover mb-0">
          <thead>
            <tr>
              <th width="40">ลำดับ</th>
              <th width="100">เลขที่</th>
              <th width="150" class="pb-1">ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <!-- <th width="140">อัตราจ่าย</th> -->
              <th width="120">หวย</th>
              <th width="100">งวด</th>
              <th width="180">เวลา</th>
              <th width="100">รายการ</th>
              <th width="100">ยอดบิล</th>
              <th width="100">ส่วนลด</th>
              <th width="100">สุทธิ</th>
              <th width="100">ถูกรางวัล</th>
              <th width="120">สถานะ</th>
              <th>หมายเหตุ</th>
              <th width="60"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item._id" :class="item.status==='Canceled'?'alert-danger':''">
              <td class="text-center">{{index+1}}</td>
              <td class="text-center">{{item.ticketNo}}</td>
              <td class="text-center">
                {{ item.account.mainUser.username }}
                <small v-if="showName" class="text-secondary">({{item.account.mainUser.firstName}})</small>
              </td>
              <!-- <td class="text-center">{{item.rate.rateTitle}}</td> -->
              <td class="text-center">{{item.market.marketTitle}}</td>
              <td class="text-center">{{item.round.roundDate}}</td>
              <td class="text-center">
                {{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}
              </td>
              <td class="text-center">{{summary(item, 'length')}}</td>
              <td class="text-right text-success">{{summary(item, 'amount') | amountFormat}}</td>
              <td class="text-right text-danger">{{summary(item, 'discount') | amountFormat}}</td>
              <td class="text-right text-success">{{summary(item, 'credit') | amountFormat}}</td>
              <td class="text-right text-success">{{summary(item, 'won') | amountFormat}}</td>
              <td class="text-center">
                <TicketStatus :ticket="item" />
              </td>
              <td class="text-center">{{item.remarks}}</td>
              <td class="text-center p-0">
                <button class="btn btn-outline-info btn-sm m-1" title="ดูรายการแทง" @click="viewTicket(item._id)"><i class="fas fa-list"></i></button>
                <button v-if="item.status!=='Canceled'" class="btn btn-danger btn-sm m-1" title="ยกเลิกโพย" @click="cancelTicket(item)"><i class="fas fa-times"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ViewTicketModal :is-show="isShowTicketModal" :ticket-id="viewTicketId" @close="isShowTicketModal=false" />
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import LottoService from '@/services/lottoService'
import TicketStatus from '@/views/components/TicketStatus'
import ViewTicketModal from '@/views/manage-lotto/components/ViewTicketModal'
import cryptoRandomString from 'crypto-random-string'

export default {
  name: 'Last500Tickets',
  components: {
    TicketStatus,
    ViewTicketModal
  },
  data() {
    return {
      data: null,
      isLoading: false,
      showName: false,
      isShowTicketModal: false,
      viewTicketId: null,
      refreshInterval: null,
      countDown: 299
    }
  },
  computed: {
    items() {
      if(!this.data)
        return []

      return this.data
    }
  },
  methods: {
    getTickets() {
      this.isLoading = true
      LottoService.getRecordingTickets()
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
        this.countDownRefresh()
      })
    },
    viewTicket(ticketId) {
      this.isShowTicketModal = true
      this.viewTicketId = ticketId
    },
    summary(item, key) {
      return item?.summary?.[key] || '0'
    },
    reload() {
      this.getTickets()
    },
    countDownRefresh() {
      this.countDown = 299
      clearInterval(this.refreshInterval)
      this.refreshInterval = setInterval(()=>{
        if(!this.isLoading) {
          this.countDown--
          if(this.countDown < 1) {
            this.reload()
          }
        }
      }, 1000)
    },
    cancelTicket(ticket) {
      if(!this.data)
        return

      const uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      console.log(ticket)
      Swal.fire({
        title: '<span class="text-danger">ยืนยันยกเลิกโพย!</span>',
        html: `<h4>สมาชิก <span class="text-primary">${ticket.account.mainUser.username}</span><br>รายการแทง <span class="text-primary">${ticket?.summary?.length}</span> รายการ<br>ยอดรวม <span class="text-primary">${ticket?.summary?.amount}</span> บาท</h4>`,
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#dc3545',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn-danger'
        }
      })
      .then((res)=>{
        if(res.isConfirmed) {
          this.isLoading = true
          LottoService.cancelTicket(ticket._id, uniqueCode).then((response)=>{
            if(response.success) {
              this.$notify({
                type: 'success',
                title: 'ยกเลิกโพยแล้ว!'
              })
              this.getTickets()
            }else{
              throw new Error(response?.message)
            }
          })
          .catch((e)=>{
            Swal.fire({
              title: 'ผิดพลาด!',
              text: (e?.message || e?.data) || 'ยกเลิกโพยไม่สำเร็จ',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
          .finally(()=>{
            this.isLoading = false
          })
        }
      })
    }
  },
  mounted() {
    this.getTickets()
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  }
}
</script>
<style lang="scss" scoped>
.table {
  border-left: 0;
  border-right: 0;

  thead {
    tr {
      th {
        text-align: center;
        font-weight: 500;
        line-height: 1;
        font-size: 85%;
      }

      th:first-child {
        border-left: 0;
      }
      th:last-child {
        border-right: 0;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 85%;
      }
      th {
        padding: 5px;
        font-size: 85%;
      }

      th:first-child, td:first-child {
        border-left: 0;
      }
      th:last-child, td:last-child {
        border-right: 0;
      }
    }
  }
  tfoot {
    tr {
      td {
        font-size: 85%;
      }
    }
  }
}
</style>
